.about-us-container {
  font-size: 19px;

  .about-us-title {
    font-size: var(--title-font-size-mobile);
    text-align: center;
    font-weight: 400;
  }
}

@media (min-width: 600px) {
  .about-us-container {
    .about-us-title {
      font-size: var(--title-font-size);
    }
  }
}
