.about-us-mobile-container {
    font-size: var(--subtitle-font-size-mobile);
    text-align: center;
}


@media (min-width: 600px) {
    .about-us-mobile-container {
        display: none;
    }
}